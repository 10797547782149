import { Box } from "@mui/material";
import { mergeSx } from "~/helpers";
import { SVGBoxProps } from "./SVGBoxProps";

export const Logo = ({ color, sx, svgProps, ...boxProps }: SVGBoxProps) => {
  const purple = color ? "currentColor" : "#3f1a73";
  const pink = color ? "currentColor" : "#f26ddc";
  const teal = color ? "currentColor" : "#5cf2f2";
  const yellow = color ? "currentColor" : "#f2ca50";
  return (
    <Box
      sx={mergeSx(
        {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        sx
      )}
      color={color}
      {...boxProps}
    >
      <svg viewBox="0 0 132.55 320.98" height="100%" {...svgProps}>
        {!color && (
          <>
            <path
              d="M56.33,160.06c2.82,1.99,6.24,3.18,9.94,3.18,0,0,0,0,0,0,3.71,0,7.12-1.19,9.94-3.18,4.45-3.14,7.38-8.3,7.38-14.15s-2.93-11.01-7.38-14.15c-2.82-1.99-6.24-3.18-9.94-3.18h0c-3.7,0-7.12,1.19-9.94,3.18-4.45,3.14-7.39,8.3-7.39,14.15s2.93,11.01,7.39,14.15Z"
              fill="#3f1a73"
            />
            <path
              d="M66.28,237.38h0c-3.7,0-7.12,1.19-9.94,3.18-4.45,3.14-7.39,8.3-7.39,14.15s2.93,11.01,7.39,14.15c2.82,1.99,6.24,3.18,9.94,3.18,0,0,0,0,0,0,3.71,0,7.12-1.19,9.94-3.18,4.45-3.14,7.38-8.3,7.38-14.15s-2.93-11.01-7.38-14.15c-2.82-1.99-6.24-3.18-9.94-3.18Z"
              fill="#3f1a73"
            />
          </>
        )}
        <path d="M76.22,220.66v-40.83h-19.89v41.32" fill={purple} />
        <path
          d="M66.28,217.49c-3.45,0-6.77,.51-9.94,1.39-15.7,4.37-27.27,18.75-27.27,35.83s11.57,31.46,27.27,35.83c3.17,.88,6.5,1.39,9.94,1.39s6.77-.51,9.95-1.39c15.7-4.37,27.27-18.75,27.27-35.83s-11.57-31.46-27.27-35.83c-3.17-.88-6.5-1.39-9.94-1.39h0Zm17.33,37.22c0,5.85-2.93,11.01-7.38,14.15-2.82,1.99-6.24,3.18-9.94,3.18,0,0,0,0,0,0-3.7,0-7.12-1.19-9.94-3.18-4.45-3.14-7.39-8.3-7.39-14.15s2.93-11.01,7.39-14.15c2.82-1.99,6.24-3.18,9.94-3.18h0c3.71,0,7.12,1.19,9.94,3.18,4.45,3.14,7.38,8.3,7.38,14.15Z"
          fill={pink}
        />
        <path
          d="M76.22,80.47v-7.43l-19.89-.27v7.69C24.5,85.29,0,112.76,0,145.92v108.78c0,36.55,29.73,66.28,66.28,66.28s66.28-29.73,66.28-66.28v-108.78c0-33.16-24.5-60.62-56.33-65.44Zm0,31.81v-11.65c20.81,4.57,36.44,23.12,36.44,45.29v108.78c0,25.58-20.81,46.39-46.39,46.39s-46.39-20.81-46.39-46.39v-108.78c0-22.16,15.63-40.72,36.44-45.29v11.88"
          fill={purple}
        />
        <path
          d="M29.06,37.22c0,3.45,.51,6.77,1.4,9.94,4.41,15.7,18.95,27.27,36.19,27.27,20.73,0,37.59-16.7,37.59-37.22S87.38,0,66.65,0c-17.25,0-31.78,11.57-36.19,27.27-.89,3.17-1.4,6.5-1.4,9.94h0Zm37.59-17.33c9.65,0,17.5,7.77,17.5,17.33s-7.85,17.33-17.5,17.33-17.5-7.77-17.5-17.33,7.85-17.33,17.5-17.33Z"
          fill={yellow}
        />
        <path
          d="M29.06,145.92c0,17.07,11.57,31.46,27.27,35.83,3.17,.88,6.5,1.39,9.94,1.39h0c3.45,0,6.77-.51,9.94-1.39,15.7-4.37,27.27-18.75,27.27-35.83s-11.57-31.46-27.27-35.83c-3.17-.88-6.5-1.39-9.94-1.39h0c-3.45,0-6.77,.51-9.94,1.39-15.7,4.37-27.27,18.75-27.27,35.83Zm27.27-14.15c2.82-1.99,6.24-3.18,9.94-3.18h0c3.71,0,7.12,1.19,9.94,3.18,4.45,3.14,7.38,8.3,7.38,14.15s-2.93,11.01-7.38,14.15c-2.82,1.99-6.24,3.18-9.94,3.18,0,0,0,0,0,0-3.7,0-7.12-1.19-9.94-3.18-4.45-3.14-7.39-8.3-7.39-14.15s2.93-11.01,7.39-14.15Z"
          fill={teal}
        />
      </svg>
    </Box>
  );
};
